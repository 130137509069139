




































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import {
  ZuzhijiagouList,
  UsersList,
  BanApplyAccount,
  ResetPwd,
  Unlock,
  ImportUser,
  downloadMuban,
} from "@/request/manage";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends mixins(listMixin) {
  private isUpdate: any = true;
  private ifShowForbid: any = false;
  private curUser: any = {};
  private jigous: any[] = [];
  private users: any[] = [];
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get user() {
    return this.$store.state.user;
  }
  private downloadMuban() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    downloadMuban(this, loading).then((res: any) => {
      window.open(res);
      loading.close();
    });
  }
  private ImportData(d: any) {
    const fileObj = d.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    ImportUser(this, form, loading)
      .then((data: any) => {
        loading.close();
        if (data == "上传成功") {
          this.$message.success("创建成功!");
          this.getList();
        } else {
          this.$alert(data, "", {
            customClass: "commonConfirm",
            confirmButtonText: "确定",
          }).then(() => {
            this.getList();
          });
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private jiesuo(items: any) {
    this.$confirm("确定要解锁改账号吗？", "解锁", {
      customClass: "commonConfirm",
    }).then(() => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        user_id: items.user_id,
      };
      Unlock(this, params, loading)
        .then(() => {
          loading.close();
          this.$message.success("解锁成功");
          this.getList();
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  private changeHide(item: any, val: any) {
    item.hide = val;
    this.isUpdate = !this.isUpdate;
  }
  private cancelForbid() {
    this.ifShowForbid = false;
    // this.getList();
  }
  private confirmForbid() {
    let arr: any = [];
    this.curUser["角色"].forEach((ele: any) => {
      arr.push({
        role_id: ele.role.role_id,
        status: ele.role.status,
      });
    });
    const params: any = {
      user_id: this.curUser.user_id,
      角色: arr,
    };
    BanApplyAccount(this, params).then((data: any) => {
      this.ifShowForbid = false;
      this.$message.success("修改成功");
      this.getList();
    });
  }
  /**
   * @description 按照机构来查找
   */
  private searchInstitution(id: any, canRead: any) {
    if (!canRead) {
      this.$message.warning("暂无权限查看");
      return;
    }
    this.filters["institution_id"] = id;
    this.search();
  }
  /**
   * @description 跳转用户编辑
   */
  private jumpCompose(user: any) {
    if (user["状态"] === "禁用") {
      this.$message.warning("该账户已经禁用，不能编辑");
      return;
    }
    const roleId = user["角色"][0]["role"]["role_id"];
    const zuzhiLength = user["角色"][0]["组织机构"].length;
    const insId =
      user["角色"][0]["组织机构"][zuzhiLength - 1]["institution_id"];
    this.$store.commit("updateZhanghu", user);
    this.$router.push({
      path: "/main/manage/zhanghu/compose",
    });
  }
  /**
   * @description 跳转添加用户
   */
  private jumpAddUser() {
    this.$store.commit("updateZhanghu", {});
    this.$router.push({
      path: "/main/manage/zhanghu/compose",
    });
  }
  /**
   * @description 跳转禁用账户管理
   */
  private jumpForbid() {
    this.$router.push({
      path: "/main/manage/zhanghu/forbid",
    });
  }
  /**
   * @description 重置用户密码
   */
  private resetPwd(user: any) {
    this.$confirm("确定要重置该账号的密码吗？", "重置密码", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          user_id: user.user_id,
        };
        ResetPwd(this, params).then((data: any) => {
          this.$message.success("重置成功");
          this.getList();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  /**
   * @description 禁用或启用账号
   */
  private banApplyAccount(user: any) {
    this.curUser = JSON.parse(JSON.stringify(user));
    this.ifShowForbid = true;
    return;
    const action: any = user["状态"] === "禁用" ? "启用" : "禁用";
    if (action === "禁用") {
      this.$confirm("禁用后该账户不能进入该网站，确定要禁用吗？", action, {
        customClass: "commonConfirm",
      })
        .then(() => {
          const params: any = {
            user_id: user.user_id,
            action,
          };
          BanApplyAccount(this, params).then((data: any) => {
            this.$message.success(action + "成功");
            this.getList();
          });
        })
        .catch((e) => {
          console.log("取消了");
        });
    } else {
      const params: any = {
        user_id: user.user_id,
        action,
      };
      BanApplyAccount(this, params).then((data: any) => {
        this.$message.success(action + "成功");
        this.getList();
      });
    }
  }
  /**
   * @description 自动计算角色
   */
  private calJuese(juese: any) {
    let jigouText = "";
    juese["组织机构"].forEach((jigou: any) => {
      jigouText += jigou["名称"] + "-";
    });
    jigouText = jigouText.substring(0, jigouText.length - 1);
    return juese["role"]["名称"] + "(" + jigouText + ")";
  }
  /**
   * @description 获取组织架构列表
   */
  private fetchJigouList() {
    // const params: any = {
    //   params: {
    //     search: this.filter.search,
    //   },
    // };
    const params: any = {};
    ZuzhijiagouList(this, params).then((data: any) => {
      data.forEach((first: any) => {
        if (first["sub_institutions"]) {
          first["open"] = true;
          first["sub_institutions"].forEach((second: any) => {
            if (second["sub_institutions"]) {
              second["open"] = true;
            } else {
              second["open"] = false;
            }
          });
        } else {
          first["open"] = false;
        }
      });
      this.jigous = data;
    });
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      search: this.filters.search,
      institution_id: this.filters.institution_id,
      sort_value: this.filters.sort_value,
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
    };
    localStorage.setItem("zhanghuguanliFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    UsersList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.users = data.users;
        this.users.forEach((d: any) => {
          d.hide = true;
        });
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.users.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    const d = localStorage.getItem("zhanghuguanliFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.fetchJigouList();
    this.getList();
  }
}
